import React from "react";

import LessonLayout from "~/layouts/lesson";

import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import UnorderedList from "~/components/List/Unordered";
import Button from "~/components/button/button";

const Page = () => {
  return (
      <LessonLayout
        current_step={9}
        lesson={"Lesson 2"}
        color={"hs"}
        total_count={10}
        module_title="Separating Fact from Fiction"
      >
        <ContentWrapper>
          <H2 underline>Step 9</H2>

          <p>To conclude, return to the CBD websites you looked at in the first lesson.</p>

          <UnorderedList>
            <li>Reexamine each of the three websites.</li>
            <li>Use your rubric to score each website for accuracy of information.</li>
            <li>Be prepared to report how you scored the websites and to discuss the reasons for your scores.</li>
            <li>Consider the following questions:</li>

            <UnorderedList>
              <li className="list-circle">Did your opinion change about the quality of each of the websites?</li>
              <li className="list-circle">What are the strengths or weaknesses of each site with respect to the accuracy of information?</li>
              <li className="list-circle">Do you think you will question the accuracy of information on websites differently in the future? How will that affect your use of the internet?</li>
            </UnorderedList>
          </UnorderedList>

          <div className="flex flex-col lg:flex-row">
            <Button
              action="secondary"
              className="my-2 lg:m-2"
              color="indigo"
              href="/article/cbd/cbd-now"
              icon="external"
              title="/article/safe-environment"
            >
              CBD website 1
            </Button>
            <Button
              action="secondary"
              className="my-2 lg:m-2"
              color="indigo"
              href="/article/cbd/cbd-truth"
              icon="external"
              title="CBD website 2"
            >
              CBD website 2
            </Button>
            <Button
              action="secondary"
              className="my-2 lg:m-2"
              color="indigo"
              href="/article/cbd/cansativa-organics"
              icon="external"
              title="CBD website 3"
            >
              CBD website 3
            </Button>
          </div>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
